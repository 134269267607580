/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import Masonry from 'masonry-layout';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease'
});

// Headroom
import Headroom from "headroom.js";
var myElement = document.querySelector("header");
var headroom = new Headroom(myElement);
headroom.init();

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Animated header
var headerContent = document.querySelector('.header-content');
if (headerContent) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;
		var texts = document.querySelectorAll('.header-text ul li');
		var duration = texts.length * 150;
		var tl = gsap.timeline({
			scrollTrigger: {
				animation: tl,
				trigger: headerContent,
				start: "top top",
				end: "+=" + (duration * 2),
				scrub: true,
				pin: true
			}
		});

		for (var i = 0; i < (texts.length - 1); i++) {
			var hideText = gsap.to(texts[i], { opacity: 0, top: '40%' });
			var revealText = gsap.to(texts[i + 1], { opacity: 1, top: '50%' });
			tl.add(hideText);
			tl.add(revealText, '-=.15');
		};

		var tlProgress = gsap.timeline({
			scrollTrigger: {
				animation: tlProgress,
				start: "top top",
				end: "+=" + (duration * 2),
				scrub: true
			}
		});
		var progressAni = gsap.to('.progress-bar', { top: '50%', ease: 'linear' });
		tlProgress.add(progressAni);
	})();
}

// Masonry
var elem = document.querySelectorAll('.masonry-columns').forEach(el => {
	var msnry = new Masonry(el, {
		// options
		itemSelector: '.masonry-column',
		percentPosition: true
	});

	setTimeout(function () {
		msnry.layout();
	}, 0);

	var checkboxes = document.querySelectorAll('.expand-text');
	for (var i = 0; i < checkboxes.length; i++) {
		const toggle = false;
		checkboxes[i].addEventListener('change', e => {
			console.log("toggle");
			var expandedTextId = "#text-" + (e.target.id);
			var expandedText = document.querySelector(expandedTextId);
			if (toggle == false) {
				expandedText.style.transition = "all 5s ease";
				expandedText.style.maxHeight = "9999px";
				setTimeout(function () {
					msnry.layout();
				}, 150);
			}
			else {
				expandedText.style.transition = "all .7s ease-out";
				expandedText.style.maxHeight = "0px";
				setTimeout(function () {
					msnry.layout();
				}, 700);
			}
			toggle = !toggle;
		});
	}
});

// Load circle in content blocks
var observer = new IntersectionObserver(function (entries) {
	for (var i = 0; i < entries.length; i++) {
		if (entries[i].isIntersecting === true) {
			if (entries[i].target.classList.contains("has-circle")) {
				entries[i].target.classList.add("trigger-circle");
			}
			if (entries[i].target.classList.contains("intro-arc")) {
				entries[i].target.classList.add("rotate-circle");
			}
			if (entries[i].target.classList.contains("intro-side-arc")) {
				entries[i].target.classList.add("reveal-circle");
			}
		}
	}
}, { threshold: [0] });

document.querySelectorAll(".has-circle, .intro-side-arc, .intro-arc").forEach(el => {
	observer.observe(el);
});